/* src/App.css */
body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Arial, sans-serif;
  display: flex;
  background-color: #1e1e1e;
  color: #87ef90;
  flex-direction: column;
  align-items: center;
}

.image-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  text-align: center;
  width: 100%; /* Full width of the container */
}

.real-size-image {
  display: block;
  width: auto;
  height: auto;
  max-width: 500px; /* Prevent the image from being larger than its container */
}

.container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}

.table-container {
  flex: 1;
  padding: 10px;
  margin: 5px;
}

h1 {
  color: #48adf1;
  text-align: center;
}

.record {
  margin-bottom: 5px;
  border-bottom: 1px solid #333;
  padding-bottom: 5px;
}

.chart-image {
  max-width: 100%;
  height: auto;
}

.table-container p {
  margin: 5px 0;
}

/* Additional CSS for SignalFeed */
.signal-feed {
  padding: 5px;
  background-color: #2e2e2e;
  border-radius: 8px;
  width: 90%;
  margin: 5px auto;
}

.signal-feed h1 {
  text-align: center;
  color: #48adf1;
}

.strategies-info {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
}

.table-container {
  background-color: #3e3e3e;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: #87ef90;
}

.table-container h1 {
  text-align: center;
  color: #48adf1;
}

.record {
  background-color: #4e4e4e;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.record p {
  margin: 3px 0;
}

.chart-image {
  width: 100%;
  border-radius: 5px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.button-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.chart-container {
  width: 90%;
  height: 80vh;
}

.disclaimer {
  background-color: #2a2a2a;
  color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  margin: 20px auto;
  font-size: 0.9em;
  line-height: 1.4;
  max-width: 980px;
  text-align: center;
}