.signal-feed {
  padding: 5px;
  background-color: #1e1e1e;
  border-radius: 5px;
  width: 90%;
  margin: 5px auto;
  color: #87ef90;
}

.signal-feed h3 {
  text-align: center;
  color: #48adf1;
}

.signals-container {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.signal-record {
  background-color: #2e2e2e;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.signal-info, .strategies-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 3px;
}

.signal-info p, .strategy-info p {
  margin: 5px 10px;
}

.chart-image {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 5px;
}

.strategies-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3px;
}

.strategy-info {
  padding: 15px;
  border-radius: 5px;
  width: 48%; /* Adjust width to ensure they fit next to each other */
  color: #1e1e1e;
  margin: 3px 0;
  box-sizing: border-box;
}

.strategy-info h2 {
  text-align: center;
  color: #1e1e1e;
  margin-bottom: 3px;
}

.signal-info.long {
  background-color: #14185B; /* Blue background for LONG position */
}

.signal-info.short {
  background-color: #641E16; /* Red background for SHORT position */
}

.strategy-info.long {
  background-color: #14185B; /* Blue background for LONG position */
  color: #dddddd;
}

.strategy-info.short {
  background-color: #641E16; /* Red background for SHORT position */
  color: #dddddd;
}

.api-result-container {
  max-width: 980px;
  overflow: auto; /* Add scrolling if content overflows */
  background-color: #333; /* Dark background for code block */
  border: 1px solid #555; /* Light border for code block */
  border-radius: 5px; /* Rounded corners for code block */
  padding: 10px; /* Padding inside the code block */
  margin-bottom: 10px; /* Space below the code block */
}

.api-result-container code {
  white-space: pre-wrap; /* Wrap text to the next line if it's too long */
  font-family: 'Courier New', Courier, monospace; /* Monospace font for code */
  font-size: 14px; /* Adjust font size for readability */
  color: #ddd; /* Light color for text */
}

.chart-image-container {
  display: flex;
  justify-content: center;
  margin: 20px 0; /* Optional: add some margin for better spacing */
}
