
.maintenance-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .maintenance-page h1 {
    font-size: 2.5em;
    color: #333;
  }
  
  .maintenance-page p {
    font-size: 1.2em;
    color: #666;
  }
  